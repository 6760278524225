var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[(_vm.checkPermission('A&VTH'))?_c('router-link',{attrs:{"to":"/rapportTh"}},[_vm._v(" Analyse et validation ")]):_vm._e(),(_vm.checkPermission('FOTH') && _vm.checkPermission('GAPTH'))?_c('router-link',{class:{
      'router-link-exact-active': _vm.ComputedRouterLinkObligeeActive,
      a: !_vm.ComputedRouterLinkObligeeActive
    },attrs:{"to":_vm.isSuperAdminGetter
        ? '/obligee/gestion-appels-paiement-obligee'
        : '/obligee'}},[_vm._v(" Obligés ")]):_vm._e(),(_vm.computedShowFactureMenu)?_c('router-link',{class:{
      'router-link-exact-active': _vm.computedRouterLinkFacture,
      a: !_vm.computedRouterLinkFacture
    },attrs:{"to":_vm.computedRouterLinkActiveFacture}},[_c('div',{on:{"click":_vm.openMenuFacture}},[_vm._v(" Factures ")])]):_vm._e(),(_vm.computedRouterLinkFacture)?_c('div',{staticClass:"menu-facture"},[_c('MenuFactures',{attrs:{"itemsFactures":_vm.itemsFactures,"model":_vm.model}})],1):_vm._e(),(_vm.checkPermission('COTH'))?_c('router-link',{attrs:{"to":"/comptabilite"}},[_vm._v(" Comptabilité ")]):_vm._e(),(_vm.checkPermission('RFTH'))?_c('router-link',{attrs:{"to":"/reglement-facture"}},[_vm._v(" Règlement Factures "),(_vm.checkPermission('RFTH'))?_c('b-badge',{staticClass:"custom-badge-cdg",attrs:{"title":"paiement non lettré"}},[_vm._v(_vm._s(_vm.getBadgeReglements))]):_vm._e()],1):_vm._e(),(_vm.checkPermission('REFFTH'))?_c('router-link',{attrs:{"to":"/refresh-fature"}},[_vm._v(" Refresh factures ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }